import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyYahooTemplate = ({ image }) => {
  return (
    <React.Fragment>
      {" "}
      <CaseStudyHeaderSection
        title="Yahoo!"
        description="Content Engagement"
        image={image}
      />
      <CaseStudyDescriptionSection
        title={
          <h3>
            Brendan was presented by Yahoo with a challenge to increasing
            content engagement, generating more traffic and higher brand
            engagement
          </h3>
        }
        list={
          <ul>
            <li>
              Optimized content marketing strategy, which was developed using
              extensive A/B testing, data mining and social algorithms to locate
              the highest-performing demographic & psychographic profiles
            </li>
            <li>
              Drastically higher engagement & traffic at a significantly lower
              cost
            </li>
            <li>
              It would have cost Yahoo $6,479,548 to reach the same amount of
              traffic that we generated during the Q1’16 test for under $20k
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-mind-valley"}
        text="Explore our work with Yahoo! further. Apply to work with us"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyYahooTemplate
